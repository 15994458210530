(function () {
  'use strict';

  var loggedUserIdCookieKey = 'loggedUserId'
    , loggedUserTokenCookieKey = 'loggedUserToken'
    , loggedUserCompanyIdCookieKey = 'loggedUserCompanyId';

  angular
    .module('wbAuth')
    .config(authConfig);

  function authConfig(visorProvider, $provide) {
    var defaultState = 'dashboard'
      , loginState = 'login';
    visorProvider.notAuthorizedRoute = defaultState;
    visorProvider.homeRoute = defaultState;

    visorProvider.authenticate = function (LoggedUser, $q, $cookies, Auth, $log, $state, $location, _) {
      // ngInject is necessary for minification
      'ngInject';

      var loggedUserId = $cookies.get(loggedUserIdCookieKey)
        , loggedUserToken = $cookies.get(loggedUserTokenCookieKey)
        , loggedUserCompanyId = $cookies.get(loggedUserCompanyIdCookieKey)
        , AuthPromise = $q.defer()
        , loggedUserPromise
        , loggedUserCompanyPromise
        , path = $location.path();

      if (!loggedUserId || !loggedUserToken) {
        $log.debug('Application starts with no logged user');

        if (pathIsIgnored()) {
          $log.debug('Application starts with an ignored path');
          return $q.reject(null);
        }

        $state.go(loginState);
        return $q.reject(null);
      }

      if (!loggedUserCompanyId) {
        $log.error('Application starts with a logged user without a company!');
        $cookies.remove(loggedUserIdCookieKey);
        $cookies.remove(loggedUserTokenCookieKey);

        if (pathIsIgnored()) {
          $log.debug('Application starts with an ignored path');
          return $q.reject(null);
        }

        $state.go(loginState);
        return $q.reject(null);
      }

      Auth.setToken(loggedUserToken);

      loggedUserCompanyPromise = LoggedUser.setCompany(loggedUserCompanyId);
      loggedUserPromise = LoggedUser.setUserById(loggedUserId);

      $q
        .all([loggedUserPromise, loggedUserCompanyPromise])
        .then(function () {
          AuthPromise.resolve(loggedUserPromise);

          $log.debug('Application starts with logged user #id %s', loggedUserId);

          if (path === '/' || path === '') {
            $state.go(defaultState);
          }
        })
        .catch(function () {
          AuthPromise.reject(null);
        });

      return AuthPromise.promise;

      function pathIsIgnored() {
        return _.startsWith(path, '/order-with-contract') || _.startsWith(path, '/password-reset') || _.startsWith(path, '/company-callback') || _.startsWith(path, '/instant-callback') || _.startsWith(path, '/merchant-callback');
      }
    };

    $provide.decorator('visor', function ($delegate, $cookies) {
      var setAuthenticated = $delegate.setAuthenticated
        , setUnauthenticated = $delegate.setUnauthenticated;

      $delegate.setAuthenticated = function (loggedUser) {
        setAuthenticated(loggedUser);
        $cookies.put(loggedUserIdCookieKey, loggedUser.id, {secure: true});
      };

      $delegate.setUnauthenticated = function () {
        setUnauthenticated();
        $cookies.remove(loggedUserIdCookieKey);
        $cookies.remove(loggedUserTokenCookieKey);
        $cookies.remove(loggedUserCompanyIdCookieKey);
      };

      return $delegate;
    });

    $provide.decorator('Auth', function ($delegate, $cookies) {
      var setToken = $delegate.setToken;

      $delegate.setToken = function (token) {
        setToken(token);
        $cookies.put(loggedUserTokenCookieKey, token, {secure: true});
      };

      return $delegate;
    });
  }
}());
